import React from "react"
import "../../common.scss"
import { Footer } from "../../components/footer/Footer"
import { OurClients } from "../../components/pageHome/OurClients"
import { SEO } from "../../components/seo"
import { WriteToUs } from "../../components/writeToUs/WriteToUs"
import { WebDevelopment_PAGE_DESCRIPTION, WebDevelopment_PAGE_TITLE } from "../../constants/seo"
import { ScreenHello } from "../../components/pageWebDevelopment/ScreenHello"
import { BlockOfOpportunity } from "../../components/pageWebDevelopment/BlockOfOpportunity"
import { OurCases } from "../../components/pageHome/ourCases/OurCases"
import { Header } from "../../components/newHeader/Header"
import { getCookie } from "../../constants/getCookie"
import { Cost } from "../../components/pageHome/Cost"
import { OurRating } from "../../components/pageHome/OurRating"
import { AboutUs } from "../../components/pageHome/AboutUs"
import { OurActivity } from "../../components/pageHome/OurActivity"
import { Example } from "../../components/old/Example"
import { Reviews } from "../../components/old/reviews/Reviews"

const WebDevelopment = () => {
  const [firstVisit] = React.useState(getCookie("firstVisit"))

  return (
    <>
      <SEO title={WebDevelopment_PAGE_TITLE} description={WebDevelopment_PAGE_DESCRIPTION} />
      {/* <HeaderOld activeLink={"WebDevelopment"}/> */}
      <Header activeLink={"WebDevelopment"} />

      <ScreenHello />

      <OurClients />

      <BlockOfOpportunity />

      <OurCases />

      {!firstVisit ? (
        <>
          <Cost /> {/*Переписал*/}
          <OurRating /> {/*Переписал*/}
          <AboutUs /> {/*Переписал*/}
          <OurActivity />
          <Example />
          <Reviews />
        </>
      ) : null}

      <WriteToUs />
      <Footer />
    </>
  )
}

export default WebDevelopment
