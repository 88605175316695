import React from "react"
import styled from "astroturf"

export const BlockOfOpportunity = () => {
  return (
    <Container className={"container"}>
      <Block>
        <Title>Быстрая оценка</Title>
        <Line />
        <Description>
          В течение 1 дня проведем оценку задач по Вашему проекту и подключим выделенную команду
        </Description>
      </Block>
      <Block>
        <Title>Качество кода</Title>
        <Line />
        <Description>
          Обязательные крос-ревью кода внутри команды. Полное покрытие тестами по необходимости.
        </Description>
      </Block>
      <Block>
        <Title>Frontend разработка</Title>
        <Line />
        <Description>
          Подключим к проекту выделенные команды web разработчиков с тимлидом, менеджером и QA-специалистами
        </Description>
      </Block>
      <Block>
        <Title>Аудит и анализ кода</Title>
        <Line />
        <Description>
          В течение 2х дней проведем оценочный анализ уже существующего проекта с детальным отчетом. Быстро подключим
          наших разработчиков в помощь.
        </Description>
      </Block>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 150px;
  grid-row-gap: 80px;

  padding-top: 80px;

  @media (max-width: 1199px) {
    grid-column-gap: 50px;
  }

  @media (max-width: 991px) {
    grid-row-gap: 40px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 420px) {
    padding-top: 40px;
  }
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  color: #121e30;

  @media (max-width: 991px) {
    font-size: 24px;
  }
`

const Line = styled.div`
  width: 146px;
  height: 2px;
  background: #dfe5ec;

  margin: 20px 0;

  @media (max-width: 991px) {
    margin: 7px 0 14px 0;
  }
`

const Description = styled.div`
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #121e30;

  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 22px;
  }
`
